import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }
}

export const API_URL = 'http://apilaravel.k2asoft.com';
// export const API_URL = 'http://api.dinsvalencia.com';
